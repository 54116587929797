<template>
    <div>
        <card-header title="Edit Registration" icon="fa-pencil"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-align-left" title="Participant Types"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field :label="registration.user.last_name + ', ' + registration.user.first_name" label-position="inside">
                                <b-select v-model="registration.type" expanded>
                                    <option value="participant">Participant</option>
                                    <option value="coach">Coach</option>
                                    <option value="staff">Staff</option>
                                    <option value="waitlist">Wait List</option>
                                    <option value="cancelled">Cancelled</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column" v-if="registration.second_id">
                            <b-field :label="registration.second_user.last_name + ', ' + registration.second_user.first_name" label-position="inside">
                                <b-select v-model="registration.second_type" expanded>
                                    <option value="participant">Participant</option>
                                    <option value="coach">Coach</option>
                                    <option value="staff">Staff</option>
                                    <option value="waitlist">Wait List</option>
                                    <option value="cancelled">Cancelled</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-share-all" title="Traveling With (For Reference)"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="First Name" label-position="inside">
                                <b-input v-model="registration.second_firstname"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Last Name" label-position="inside">
                                <b-input v-model="registration.second_lastname"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Traveling With E-Mail" label-position="inside">
                                <b-input v-model="registration.second_email"/>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>

                <subheader-list-item icon="fa-align-left" title="Status"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="registration.itinerary_complete">Itinerary Complete</b-switch>
                        </div>
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="registration.flights_complete">Flights Complete</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="registration.addons_complete">Add-Ons Complete</b-switch>
                        </div>
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="registration.questions_complete">Questions Complete</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="registration.ready_to_travel">Ready to Travel</b-switch>
                        </div>
                    </div>
                </container-list-item>

                <subheader-list-item icon="fa-align-left" title="Advanced Settings"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Primary User ID" label-position="inside" message="Warning: Changing this could cause problems.">
                                <b-input v-model="registration.user_id"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Second User ID" label-position="inside" message="Warning: Changing this could cause problems.">
                                <b-input v-model="registration.second_id"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Trip ID" label-position="inside" message="Warning: Changing this could cause problems.">
                                <b-input v-model="registration.trip_id"/>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>

        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteReg"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Registration?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Registration</b-button>
                </div>
            </div>
        </card-footer>
    </div>

</template>

<script>
    import async from "async";
    import {client as http} from "../../http_client";
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "../trips/EventForm";
    import RegistrationListItem from "../../components/RegistrationListItem";
    import CardList from "../../TIER/components/CardList";
    import {mapActions} from "vuex";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    export default {
        components: {ContainerListItem, SubheaderListItem, CardList, RegistrationListItem, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function() {
            return {
                registration: {
                    trip: {},
                    user: {}
                },
                confirmDelete: false,
                dirty: null
            }
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadRegistration: function() {
                this.$emit('loading', true);
                this.dirty = null;

                async.series([
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = {...response.data};
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);

                http.put('/api/registrations/' + this.props.registrationId, {
                    type: this.registration.type,
                    second_type: this.registration.second_type,
                    trip_id: this.registration.trip_id,
                    user_id: this.registration.user_id,
                    second_firstname: this.registration.second_firstname,
                    second_lastname: this.registration.second_lastname,
                    second_email: this.registration.second_email,
                    second_id: this.registration.second_id,
                    itinerary_complete: this.registration.itinerary_complete,
                    flights_complete: this.registration.flights_complete,
                    addons_complete: this.registration.addons_complete,
                    questions_complete: this.registration.questions_complete,
                    ready_to_travel: this.registration.ready_to_travel
                }, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});

                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteReg() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/registrations/' + this.props.registrationId).then(response => {
                        this.$reloadCard('registrations', {});
                        this.$reloadCard('registration', {registrationId: this.props.registrationId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.eventId': function() {
                this.loadRegistration();
            },
            'registration': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this registration? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadRegistration();
        }
    };
</script>

<style scoped>

</style>
